/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">Add {{ vmConfigMenuFormData.config_menu_type }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">

                <div class="col-md-12 mb-3">
                  <label for="validationconfig_menu_name">
                    {{cvConfigMenuNameLabel}}</label
                  >
                  <input
                    v-model="vmConfigMenuFormData.config_menu_name"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationconfig_menu_icon">
                    {{cvConfigMenuIconLabel}}</label
                  >
                  <input
                    v-model="vmConfigMenuFormData.config_menu_icon"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationconfig_menu_link">
                  {{cvConfigMenuGroupLabel}}</label
                >
                <input
                  v-model="vmConfigMenuFormData.config_menu_grp"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationconfig_menu_link">
                  {{cvConfigMenuLinkLabel}}</label
                >
                <input
                  v-model="vmConfigMenuFormData.config_menu_link"
                  type="text"
                  class="form-control"
                  required
                />
              </div>
              <div class="col-md-12 mb-3">
                <label for="validationconfig_menu_type">
                  {{cvConfigMenuTypeLabel}}</label
                >
                <select class="form-control"  v-model="vmConfigMenuFormData.config_menu_type">
                  <option  v-for="(menu_type, index) of menuType" :key="(index+1)" :value="index" >
                    {{menu_type}}
                  </option>
                </select>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="configMenuAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { ConfigMenus } from "../../../FackApi/api/ConfigMenu"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "ConfigMenuAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvSubmitBtn: "Add",
      cvConfigMenuNameLabel: "Menu name",
      cvConfigMenuIconLabel: "Menu icon",
      cvConfigMenuLinkLabel: "Menu link",
      cvConfigMenuTypeLabel: "Menu type",
      cvConfigMenuGroupLabel: "Menu Group",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Added",
      vmConfigMenuFormData: Object.assign({}, this.initFormData()),
      vmConfigMenuDesc: null,
      vmConfigMenuType: null,
      menuType: {
        Configurations: "Configurations",
        Reports: "Reports"
      }
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "config_menu_name": "",
        "config_menu_icon": "",
        "config_menu_link": "",
        "config_menu_grp": "",
        "config_menu_type": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmConfigMenuFormData) {
          if (!this.vmConfigMenuFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * configMenuAdd
     */
    async configMenuAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let configMenuAddResp = await ConfigMenus.configMenuAdd(this, this.vmConfigMenuFormData)
        await ApiResponse.responseMessageDisplay(this, configMenuAddResp)
        if (configMenuAddResp && !configMenuAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseConfigMenuAddModal", configMenuAddResp.resp_data)
        }
        this.vmConfigMenuFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at configMenuAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
